document.addEventListener("DOMContentLoaded", () => {
	var ua = navigator.userAgent
	var el
	if (ua.indexOf("MSIE ") >= 0 || ua.indexOf("Trident/") >= 0) {
		// Show #ie element if IE is detected
		el = document.getElementById("ie")
	} else {
		// Show #script element otherwise
		el = document.getElementById("script")
	}
	if (el) {
		el.style.display = "block"
	}
})
